import { useUser } from "@app/hooks/use-user";
import useSWR from "swr";

export type ClientSummariesParams = {
	limit?: number;
	search?: string;
	offset?: number;
	trade_status?: string;
	ait_status?: string;
	ordering?: string;
};

export type ClientSummariesResponse = {
	results: Array<{
		id: number;
		first_name: string;
		last_name: string;
		funds_available: number;
		funds_last_updated: {
			datetime: string;
			string: string;
			warning_message: string;
		};
		minimum_return: number | null;
		allowance_available: {
			sda: number;
			sda_details: {
				sda_unused: number;
				reserved: number;
				sda_used: number;
			};
			fia: number;
			fia_details: {
				available: {
					label: string;
					amount: number;
				};
				pending: {
					label: string;
					amount: number;
					application_date: string | null;
				};
				locked: {
					label: string;
					amount: number;
				};
			};
		};
		total_profit: number;
		trade_status: {
			id: number | null;
			position: number | null;
			label: string;
			amount_invested: number | null;
			net_profit: number | null;
			statement: boolean;
		};
		onboarding_complete: boolean;
	}>;
	count: number;
};

export const useClientSummaries = (params: ClientSummariesParams) => {
	const { data: user } = useUser();
	return useSWR<ClientSummariesResponse>(
		user ? ["/client_list/", { params }] : null,
	);
};
